import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { config_options, dashboardConfigsSelectors, updateConfig } from "../../../../store/dashboardConfigsSlice";
import {setAlertNotificationArgs} from "../../../../store/alertsNotificationSlice";
import { useParams } from "react-router-dom";
import { Popover, Button, TextField, Tooltip } from "@mui/material";
import { generateDisplayConfigUpdateArgs, generateDisplayConfigUpdateNotification } from "./DisplayConfigUpdateUtils";

const DisplayConfigNotesInput = ({ handleUpdate, notes, disableActions }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const [message, setMessage] = useState("");

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        setMessage(notes);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = () => {
        handleUpdate(message);
        setOpen(false);
        setTimeout(() => {
            setMessage("");
        }, 300);
    };

    return (
        <>
            {/* Render the notes initially, which can be clicked */}
            <Tooltip title={`Click to ${!notes ? "add" : "edit"} notes`} followCursor>
                <div
                    ref={anchorRef}
                    onClick={disableActions ? null : handleToggle}
                    style={{
                        cursor: 'pointer',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',      // Ensure the div occupies the full cell width
                        height: '100%',     // Ensure the div occupies the full cell height
                        display: 'flex',    // This allows for easy centering
                        alignItems: 'center', // Vertically center the text
                        padding: '0 8px'    // Optional padding to prevent text touching the edges
                    }}
                >
                    {notes}
                </div>
            </Tooltip>
            {/* Popover that contains the TextField */}
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: 10, width: "350px", borderRadius: "10px" }}>
                    <TextField
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={6}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                    />
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, width: "100%" }}
                    >
                        Save
                    </Button>
                </div>
            </Popover>
        </>
    )
}

export const DisplayConfigNotes = ({ row, displayConfigUuid, disableActions }) => {
    // TODO: needs to display matching displayConfigTableRow displayConfigOptions.notes value
    // onClick -> needs to expand to textbox w/ save button at bottom

    const dispatch = useDispatch();
    const page_params = useParams();
    const user = useSelector((state) => state.role.name);
    const isInternal = useSelector((state) => state.role.isInternal);
    const companyUuid = useSelector(state => state.dashboard.companyUuid);

    const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
    const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);
    const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
    const displayConfigTableRow = displayConfigTableRows?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([field, value]) => row[field] === value));
    const displayConfigOptions = displayConfigTableRow?.displayConfigOptions;
    const notes = (displayConfigOptions && displayConfigOptions[config_options.notes]) ? displayConfigOptions[config_options.notes] : ""
    
    const handleUpdate = (notes) => {
        //generateDisplayConfigUpdateArgs = (companyUuid, newConfigValue, configAction, row, displayConfigUuid, primaryKeyFieldList, linkedTableForeignFieldMap)
        //generateDisplayConfigUpdateNotification = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid)
        const configUpdateArgs = generateDisplayConfigUpdateArgs(companyUuid, notes, config_options.notes, row, displayConfigUuid, primaryKeyFieldList, linkedTableForeignFieldMap);
        // console.log("configUpdateArgs", configUpdateArgs);
        if (!isInternal && !companyUuid) {// companyUuid means sysAdmin viewing dashboard
        const configUpdateNotification = generateDisplayConfigUpdateNotification(notes, config_options.notes, row, user, displayConfigUuid, page_params.dashboardID);
        console.log("configUpdateNotification", configUpdateNotification);
        dispatch(setAlertNotificationArgs(configUpdateNotification));
        }
        dispatch(updateConfig(displayConfigUuid, configUpdateArgs));
    }

    return (<DisplayConfigNotesInput handleUpdate={handleUpdate} notes={notes} disableActions={disableActions}/>);
}

export default DisplayConfigNotes;